import { useRef } from "react";

const useEnterButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      buttonRef.current?.click();
    }
  };

  return { buttonRef, handleKeyDown };
};
export default useEnterButton;
