import { ButtonProps } from "@chakra-ui/react";

const buttonProps = (valid: boolean = true): ButtonProps => ({
  opacity: valid ? 1 : 0,
  pointerEvents: valid ? "auto" : "none",
  size: ["md", "xl"],
  fontSize: ["xl", "3xl"],
  px: "8",
  py: "4",
  borderRadius: "2xl",
  width: ["2xs", "sm"],
});

export default buttonProps;
