import { Flex, Text } from "@chakra-ui/react";

type Props = { top: string; mid: string; bottom: string };
const TriMoji = ({ top, mid, bottom }: Props) => (
  <Flex position="relative" justifyContent="center" mb="280">
    <Text
      transform={["translateY(10%)", "translateY(0%)"]}
      position="absolute"
      fontSize={[180, 220, 240]}
    >
      {top}
    </Text>
    <Text
      transform={["translateY(-20%)", "translateY(-40%)"]}
      position="absolute"
      fontSize={[180, 220, 240]}
    >
      {mid}
    </Text>
    <Text
      transform={["translateY(0%)", "translateY(-10%)"]}
      position="absolute"
      fontSize={[160, 180, 200]}
    >
      {bottom}
    </Text>
  </Flex>
);

export default TriMoji;
