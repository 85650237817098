import { Geven } from "../../../types/Slides";
import { Button, Textarea } from "@chakra-ui/react";
import Title from "../../../components/Title";
import buttonProps from "../../../theme/buttonProps";
import useEnterButton from "../../../hooks/useEnterButton";
import HappyCompliment from "../../../components/visuals/HappyCompliment";
import inputProps from "../../../theme/inputProps";
import Personalia from "../../../types/Personalia";
import { useState } from "react";

type Props = {
  setSlide: (slide: Geven) => void;
  personalia?: Personalia;
};
const Complimentje: React.FC<Props> = ({ setSlide, personalia }) => {
  const [compliment, setCompliment] = useState("");
  const complimentValid = compliment.length > 1;
  const handleSubmit = async () => {
    console.log("💩 Sending Feedback");
    if (personalia?.name && personalia?.email && complimentValid) {
      fetch(
        `/api/send-feedback?name=${encodeURIComponent(personalia.name)}&feedback=${encodeURIComponent(compliment)}&compliment=true`,
      ).then((data) => {
        setSlide(Geven.Dank_Feedback);
        console.log(data);
      });
    }
  };
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title
        visual={HappyCompliment}
        title="Nice!"
        subtitle="Heb je nog een compliment?"
      />
      <Textarea
        {...inputProps()}
        minHeight={200}
        onKeyDown={handleKeyDown}
        /*@ts-ignore*/
        onInput={(e) => setCompliment(e.target.value)}
      />
      {complimentValid && (
        <Button
          ref={buttonRef}
          {...buttonProps()}
          colorScheme="green"
          onClick={handleSubmit}
        >
          Top!
        </Button>
      )}
    </>
  );
};

export default Complimentje;
