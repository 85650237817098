import * as React from "react";
import { useMemo } from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import parseFeedbackUrl from "./util/parseFeedbackUrl";
import Switch from "./layout/Switch";

export const App = () => {
  const feedbackUrl = useMemo(() => parseFeedbackUrl(), []);
  return (
    <ChakraProvider theme={theme}>
      <Flex
        height="100vh"
        width="100vw"
        overflow="hidden"
        justifyContent="center"
      >
        <Switch url={feedbackUrl} />
      </Flex>
    </ChakraProvider>
  );
};
