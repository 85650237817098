import { Geven } from "../../../types/Slides";
import { Button, Link } from "@chakra-ui/react";
import Title from "../../../components/Title";
import buttonProps from "../../../theme/buttonProps";
import CelebrateCompliment from "../../../components/visuals/CelebrateCompliment";

type Props = {
  setSlide: (slide: Geven) => void;
};
const ComplimentjeDank: React.FC<Props> = ({ setSlide }) => {
  const handleSubmit = () => {
    setSlide(Geven.Dank_Feedback);
    console.log("Send Mail");
  };

  return (
    <>
      <Title
        visual={CelebrateCompliment}
        title="Wh00t!"
        body="Laten we gauw weer feestje bouwen..."
      />

      <Link href="/">
        <Button
          {...buttonProps}
          colorScheme="gray"
          onClick={handleSubmit}
          mt="36"
          opacity="0.75"
          fontSize="md"
          size="sm"
          width="xs"
        >
          Ook feedback vragen?
        </Button>
      </Link>
    </>
  );
};

export default ComplimentjeDank;
