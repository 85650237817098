import React, { useState } from "react";
import { Vragen } from "../types/Slides";
import Naam from "../pages/vragen/Naam";
import Personalia from "../types/Personalia";
import Email from "../pages/vragen/Email";
import Kopieren from "../pages/vragen/Kopieren";
import Selecteer from "../pages/vragen/Selecteer";

const VragenPages: React.FC = () => {
  const [slide, setSlide] = useState<Vragen>(Vragen.Naam_Invullen);
  const setSlideCallback = (slide: Vragen) => setSlide(slide);

  const [info, setInfo] = useState<Personalia>({});
  const setNameCallback = (name: string) => setInfo({ ...info, name });
  const setEmailCallback = (email: string) => setInfo({ ...info, email });
  const Slide = () => {
    switch (slide) {
      case Vragen.Naam_Invullen:
        return <Naam setSlide={setSlideCallback} setName={setNameCallback} />;
      //   TODO: If this takes off sort out the reputation with SendGrid
      case Vragen.Email_Invullen:
        return (
          <Email setSlide={setSlideCallback} setEmail={setEmailCallback} />
        );
      case Vragen.Selecteren:
        return (
          <Selecteer setSlide={setSlideCallback} setName={setEmailCallback} />
        );
      case Vragen.Link_Kopieren:
        return <Kopieren personalia={info} />;
    }
  };

  return <Slide />;
};

export default VragenPages;
