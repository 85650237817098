import { Button, Stack, useColorModeValue } from "@chakra-ui/react";
import { Geven } from "../types/Slides";
import buttonProps from "../theme/buttonProps";

type Props = {
  setSlide: (slide: Geven) => void;
};
const YesNoOptions: React.FC<Props> = ({ setSlide }) => {
  const yesBg = useColorModeValue("red.400", "red.600");
  const noBg = useColorModeValue("green.200", "green.600");

  const handleNo = () => setSlide(Geven.Complimentje);
  const handleYes = () => setSlide(Geven.Feedback);
  const extraButtonProps = {
    px: [4, 16, 24],
    py: [2, 8, 6],
    fontSize: ["xl", "2xl", "3xl"],
    color: "white",
    maxWidth: ["md", "lg", "xl"],
    minWidth: ["sm", "md", "lg"],
  };
  return (
    <Stack
      direction={["column"]}
      justifyContent="center"
      spacing={["2", "8"]}
      textAlign="center"
      mx={[4, 16, 24]}
      mt="6"
    >
      <Button
        {...buttonProps}
        {...extraButtonProps}
        bg={noBg}
        onClick={handleNo}
      >
        Nee
      </Button>
      <Button
        {...buttonProps}
        {...extraButtonProps}
        bg={yesBg}
        onClick={handleYes}
      >
        Ja
      </Button>
    </Stack>
  );
};

export default YesNoOptions;
