import { Heading, Text, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  visual: ReactNode;
  title: string;
  subtitle?: string;
  body?: string;
};
const Title: React.FC<Props> = ({ visual, title, subtitle, body }) => {
  return (
    <VStack textAlign="center" gap={2} px={[2, 8, 12]}>
      {visual}
      <Heading fontSize={["3xl", "5xl", "6xl"]} fontWeight="bold">
        {title}
      </Heading>
      {subtitle && (
        <Text fontSize={["xl", "2xl", "3xl"]} margin={["lg"]}>
          {subtitle}
        </Text>
      )}
      {body && (
        <Text fontWeight="regular" fontSize={["lg", "2xl", "3xl"]}>
          {body}
        </Text>
      )}
    </VStack>
  );
};

export default Title;
