const inputProps = (valid: boolean = false) => ({
  autoComplete: "off",
  fontSize: ["lg", "2xl", "4xl"],
  borderRadius: "8",
  px: "8",
  py: "2",
  minHeight: 16,
  mt: [2, 8],
  mx: [20, 8, 12],
  lineHeight: 1.5,
  colorScheme: "white",
  maxWidth: ["2xs", "sm", "xl"],
  mb: "8",
  borderWidth: 4,
  background: "white",
  fontFamily: "mono",
  fontWeight: "semiBold",
  color: "blue.900",
  autoFocus: true,
  borderColor: valid ? "green.800" : "gray.300",
});

export default inputProps;
