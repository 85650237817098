import { Vragen } from "../../types/Slides";
import { Button, Flex, Input, Text } from "@chakra-ui/react";
import Title from "../../components/Title";
import buttonProps from "../../theme/buttonProps";
import { useRef, useState } from "react";
import inputProps from "../../theme/inputProps";
import useEnterButton from "../../hooks/useEnterButton";

type Props = {
  setSlide: (slide: Vragen) => void;
  setEmail: (name: string) => void;
};
const Email: React.FC<Props> = ({ setSlide, setEmail }) => {
  const visual = (
    <Flex position="relative" justifyContent="center" mb="280">
      <Text position="absolute" fontSize="240">
        🤲
      </Text>
      <Text mt="-160" position="absolute" fontSize="240">
        ✨
      </Text>
      <Text position="absolute" fontSize="180">
        ✉️
      </Text>
    </Flex>
  );
  const [email, updateEmail] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);
  const handleSubmit = () => {
    if (!valid) {
      return;
    }
    setSlide(Vragen.Link_Kopieren);
    let email = input.current?.value;
    if (email) {
      setEmail(email);
    }
  };
  const valid = email.length > 1;
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title visual={visual} title="Waar zullen we het heen sturen?" />
      <Input
        ref={input}
        {...inputProps()}
        onChange={(e) => updateEmail(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Email..."
      />
      <Button ref={buttonRef} {...buttonProps(valid)} onClick={handleSubmit}>
        OK
      </Button>
    </>
  );
};

export default Email;
