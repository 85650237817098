import { Geven } from "../../../types/Slides";
import { Button, Textarea } from "@chakra-ui/react";
import Title from "../../../components/Title";
import buttonProps from "../../../theme/buttonProps";
import useEnterButton from "../../../hooks/useEnterButton";
import inputProps from "../../../theme/inputProps";
import ShockedFeedback from "../../../components/visuals/ShockedFeedback";
import Personalia from "../../../types/Personalia";
import { useState } from "react";

type Props = {
  setSlide: (slide: Geven) => void;
  personalia?: Personalia;
};
const GevenFeedback: React.FC<Props> = ({ setSlide, personalia }) => {
  const [feedback, setFeedback] = useState("");
  const feedbackValid = feedback.length > 1;
  const handleSubmit = async () => {
    console.log("💩 Sending Feedback");
    if (personalia?.name && personalia?.email && feedbackValid) {
      fetch(
        `/api/send-feedback?name=${encodeURIComponent(personalia.name)}&feedback=${encodeURIComponent(feedback)}&compliment=false`,
      ).then((data) => {
        setSlide(Geven.Dank_Feedback);
        console.log(data);
      });
    }
  };
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title visual={ShockedFeedback} title="Oh joh!" subtitle="Hoezo?" />
      <Textarea
        minH="120"
        {...inputProps(feedbackValid)}
        minHeight={200}
        onKeyDown={handleKeyDown}
        /*@ts-ignore*/
        onInput={(e) => setFeedback(e.target.value)}
      />
      {feedbackValid && (
        <Button
          ref={buttonRef}
          {...buttonProps()}
          colorScheme="green"
          onClick={handleSubmit}
        >
          Klaar!
        </Button>
      )}
    </>
  );
};

export default GevenFeedback;
