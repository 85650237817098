import { Vragen } from "../../types/Slides";
import { Button, Input } from "@chakra-ui/react";
import Title from "../../components/Title";
import buttonProps from "../../theme/buttonProps";
import { useRef, useState } from "react";
import inputProps from "../../theme/inputProps";
import BlessedPoop from "../../components/visuals/BlessedPoop";
import useEnterButton from "../../hooks/useEnterButton";

type Props = {
  setSlide: (slide: Vragen) => void;
  setName: (name: string) => void;
};
const Naam: React.FC<Props> = ({ setSlide, setName }) => {
  const [name, updateName] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);
  const handleSubmit = () => {
    if (!valid) {
      return;
    }
    setSlide(Vragen.Selecteren);
    let name = input.current?.value;
    if (name) {
      setName(name);
    }
  };
  const valid = name.length > 1;
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title visual={BlessedPoop} title="Van wie wil je feedback?" />

      <Input
        ref={input}
        {...inputProps()}
        maxWidth={["2xs", "sm", "xl"]}
        placeholder="Naam..."
        onKeyDown={handleKeyDown}
        onChange={(e) => updateName(e.target.value)}
      />

      <Button ref={buttonRef} {...buttonProps(valid)} onClick={handleSubmit}>
        OK
      </Button>
    </>
  );
};

export default Naam;
