import type {StyleFunctionProps} from '@chakra-ui/react';
import {extendTheme} from '@chakra-ui/react';
import {mode} from '@chakra-ui/theme-tools';

export const theme = extendTheme({
    styles: {
        global: (props: StyleFunctionProps) => ({
            body: {
                fontFamily: 'body',
                bg: mode('#285FF4', '#041232')(props),
                color: mode('#f4f6fb', '#b8b8b8')(props),
                lineHeight: 'base',
            },
        }),
    },
    fonts: {
        heading: 'sans-serif',
        body: 'sans-serif',
    },
    components: {
        Button: {},
    },

});
