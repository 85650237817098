function parsePathFromBrowser(): string | null {
  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  if (pathname.startsWith("/feedback/")) {
    // Extract the part of the path after '/feedback/'
    return pathname.replace("/feedback/", "");
  }

  return null;
}

export default parsePathFromBrowser;
