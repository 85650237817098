import { Geven } from "../../types/Slides";
import Title from "../../components/Title";
import YesNoOptions from "../../components/YesNoOptions";
import BlessedPoop from "../../components/visuals/BlessedPoop";
import Oops from "../../components/visuals/Oops";
import Personalia from "../../types/Personalia";

type Props = {
  setSlide: (slide: Geven) => void;
  personalia?: Personalia;
};
const GevenVraag: React.FC<Props> = ({ setSlide, personalia }) => {
  return (
    <>
      {personalia === undefined ? (
        <img alt="Loading.." src={require("../../theme/loading.gif")} />
      ) : personalia?.name ? (
        <>
          <Title
            visual={BlessedPoop}
            title="Vraagje..."
            subtitle={personalia.name}
            body="Heb jij schijt aan mij?"
          />
          <YesNoOptions setSlide={setSlide} />
        </>
      ) : (
        <Title
          visual={Oops}
          title="Godver!"
          subtitle="Het gaat weer allemaal fout hier...."
        />
      )}
    </>
  );
};

export default GevenVraag;
