import React, { useEffect, useMemo, useState } from "react";
import { Geven } from "../types/Slides";
import GevenVraag from "../pages/geven/Vraag";
import GevenFeedback from "../pages/geven/feedback/Feedback";
import GevenDank from "../pages/geven/feedback/FeedbackDank";
import Complimentje from "../pages/geven/complimentje/Complimentje";
import ComplimentjeDank from "../pages/geven/complimentje/ComplimentjeDank";
import parseFeedbackUrl from "../util/parseFeedbackUrl";
import Personalia from "../types/Personalia";

const GevenPages: React.FC = () => {
  // PERSONALIA //
  const path = useMemo(() => parseFeedbackUrl(), []);
  const [personalia, setPersonalia] = useState<Personalia | undefined>(
    undefined,
  );
  const getPersonalia = async () => {
    const response = await fetch(`/api/unwrap-link?ask=${path}`);
    const data = await response.json();
    return data;
  };
  useEffect(() => {
    getPersonalia().then((data) => setPersonalia(data));
  }, []);

  const [slide, setSlide] = useState<Geven>(Geven.Vraag);
  const setSlideCallback = (slide: Geven) => setSlide(slide);
  const Slide = () => {
    switch (slide) {
      case Geven.Vraag:
        return (
          <GevenVraag personalia={personalia} setSlide={setSlideCallback} />
        );
      case Geven.Feedback:
        return (
          <GevenFeedback personalia={personalia} setSlide={setSlideCallback} />
        );
      case Geven.Complimentje:
        return (
          <Complimentje personalia={personalia} setSlide={setSlideCallback} />
        );
      case Geven.Dank_Feedback:
        return <GevenDank setSlide={setSlideCallback} />;
      case Geven.Dank_Complimentje:
        return <ComplimentjeDank setSlide={setSlideCallback} />;
    }
  };

  return <Slide />;
};

export default GevenPages;
