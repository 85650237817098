import GevenPages from "./GevenPages";
import VragenPages from "./VragenPages";
import { VStack } from "@chakra-ui/react";

type Props = {
  url: string | null;
};
const Switch: React.FC<Props> = ({ url }) => (
  <VStack justifyContent="center" mx={10}>
    {url ? <GevenPages /> : <VragenPages />}
  </VStack>
);

export default Switch;
