import { Geven } from "../../../types/Slides";
import { Button, Link } from "@chakra-ui/react";
import Title from "../../../components/Title";
import buttonProps from "../../../theme/buttonProps";
import CoffeeFeedback from "../../../components/visuals/CoffeeFeedback";

type Props = {
  setSlide: (slide: Geven) => void;
};
const GevenDank: React.FC<Props> = ({ setSlide }) => {
  const handleSubmit = () => {
    setSlide(Geven.Dank_Feedback);
    console.log("Send Mail");
  };

  return (
    <>
      <Title
        visual={CoffeeFeedback}
        title="Thanks!"
        body="Tik me volgende keer even aan, dan doen we een bakkie..."
      />
      <Link href="/">
        <Button
          {...buttonProps}
          colorScheme="gray"
          onClick={handleSubmit}
          mt="36"
          opacity="0.75"
          fontSize="md"
          size="sm"
          width="xs"
        >
          Ook feedback vragen?
        </Button>
      </Link>
    </>
  );
};

export default GevenDank;
