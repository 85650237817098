enum Geven {
  Vraag,
  Feedback,
  Complimentje,
  Dank_Feedback,
  Dank_Complimentje,
}

enum Vragen {
  Naam_Invullen,
  Email_Invullen,
  Link_Kopieren,
  Selecteren,
}

export { Vragen, Geven };
