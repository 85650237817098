import { Button, Flex, Input, Text } from "@chakra-ui/react";
import Title from "../../components/Title";
import Personalia from "../../types/Personalia";
import { useEffect, useRef, useState } from "react";
import buttonProps from "../../theme/buttonProps";
import inputProps from "../../theme/inputProps";
import useEnterButton from "../../hooks/useEnterButton";

type Props = {
  personalia: Personalia;
};
const Kopieren: React.FC<Props> = ({ personalia: { name, email } }) => {
  const [copied, setCopied] = useState(false);
  const visual = (
    <Flex position="relative" justifyContent="center" mb="280">
      <Text position="absolute" fontSize="240">
        🤲
      </Text>
      <Text mt="-160" position="absolute" fontSize="240">
        ✨
      </Text>
      <Text position="absolute" fontSize="180">
        {copied ? "✅" : "🔗"}
      </Text>
    </Flex>
  );

  // URL //
  const getPath = async (): Promise<string> => {
    const response = await fetch(
      `/api/generate-link?name=${name}&email=${email}`,
    );
    const data = await response.json();
    return data.link;
  };
  const [url, setUrl] = useState("");
  useEffect(() => {
    getPath().then((path) =>
      setUrl(`https://${window.location.host}/feedback/${path}`),
    );
  }, []);

  // COPY //
  const input = useRef<HTMLInputElement>(null);
  const handleCopy = () => {
    const copyUrl = input?.current?.value;
    if (copyUrl) {
      navigator.clipboard.writeText(copyUrl);
      setCopied(true);
    }
  };
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title
        visual={visual}
        title={copied ? `Geef 'm aan ${name}` : "Kopieer deze link..."}
      />
      <Input
        readOnly
        ref={input}
        value={url}
        onKeyDown={handleKeyDown}
        placeholder="Link..."
        {...inputProps()}
      />
      <Button ref={buttonRef} {...buttonProps()} onClick={handleCopy}>
        {copied ? "Gekopieerd" : "Kopieer"}
      </Button>
    </>
  );
};

export default Kopieren;
