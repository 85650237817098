import { Vragen } from "../../types/Slides";
import { Button, Flex, Text } from "@chakra-ui/react";
import Title from "../../components/Title";
import buttonProps from "../../theme/buttonProps";
import { useRef, useState } from "react";
import useEnterButton from "../../hooks/useEnterButton";

type Props = {
  setSlide: (slide: Vragen) => void;
  setName: (name: string) => void;
};
const Selecteer: React.FC<Props> = ({ setSlide, setName }) => {
  const visual = (
    <Flex position="relative" justifyContent="center" mb="280">
      <Text position="absolute" fontSize="240">
        🤲
      </Text>
      <Text mt="-160" position="absolute" fontSize="240">
        ✨
      </Text>
      <Text position="absolute" fontSize="180">
        ✉️
      </Text>
    </Flex>
  );
  const [select, updateSelect] = useState<string>("");
  const input = useRef<HTMLInputElement>(null);
  const handleSubmit = () => {
    setSlide(Vragen.Link_Kopieren);
    updateSelect("Sebas");
  };
  const valid = select.length > 1;
  const { handleKeyDown, buttonRef } = useEnterButton();
  return (
    <>
      <Title visual={visual} title="Waar zullen we het heen sturen?" />
      {/*<Input*/}
      {/*  ref={input}*/}
      {/*  {...inputProps()}*/}
      {/*  onChange={(e) => updateEmail(e.target.value)}*/}
      {/*  onKeyDown={handleKeyDown}*/}
      {/*  placeholder="Email..."*/}
      {/*/>*/}
      {/*<Button ref={buttonRef} {...buttonProps(valid)} onClick={handleSubmit}>*/}
      {/*  OK*/}
      {/*</Button>*/}
      <Button
        ref={buttonRef}
        onKeyDown={handleKeyDown}
        {...buttonProps(true)}
          mt={6}
        onClick={handleSubmit}
      >
        SEBAS
      </Button>
    </>
  );
};

export default Selecteer;
